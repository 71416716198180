import IconHelper from 'src/helpers/commonComponents/IconHelper';
import clsx from 'clsx';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import useDictionary from 'src/hooks/useDictionary';
import { useContext, useEffect, useState } from 'react';
import ComponentContext from 'lib/context/ComponentContext';

import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import useOcCart from 'src/hooks/useOcCart';
import { trackAddEvent } from '@sitecore-search/react';
import { RecommendationWidget } from '@sitecore-search/react/dist/esm/types';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import {
  ErrorCodes,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'src/helpers/Constants';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { useRouter } from 'next/router';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import { BuyerProductWithXp } from 'src/redux/xp';
import { PspProductRootProps } from '../search/SearchResults/PspProductRoot';
import { useProductInventory } from '../search/SearchResults/use-product-inventory';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';
import { ProductLink } from '../search/SearchResults/products/ProductLink';
import { ProductInventoryMessage } from '../search/SearchResults/products/ProductInventoryMessage';
import { ProductMoreOptions } from '../search/SearchResults/products/ProductMoreOptions';
import { ProductRecommendationPrice } from '../search/SearchResults/products/ProductPrice';
import { recommendationProductVariant } from 'tailwindVariants/components/productRecommendationTailwindVariant';
import BazarVoiceReviews from 'components/BazarVoice/BazarVoiceReviews';

type ProductProps = Omit<PspProductRootProps, 'actions'> & {
  actions: RecommendationWidget['ActionProps'];
  title?: string;
};

// type InitialState = RecommendationInitialState<'itemsPerPage'>;
export const ProductLineItemCard = ({ product, actions, index }: ProductProps) => {
  const {
    addToFavoritesWrapper,
    addToFavoriteIcon,
    sitecoreProductRoot,
    productInfoWrapper,
    sitecoreProductName,
    submitBtn,
    productBottomWrapper,
    productImage,
    productTopWrapper,
    sitecoreImageWrapper,
    productStockPriceInfo,
    sitecoreImage,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
    topTooltipWrapper,
    offerLabel,
    tooltipContainer,
    topToolTipDescription,
    moreOptionLink,
    productImageAndTitleWrapper,
  } = recommendationProductVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // Get the OC product so we can get promo tags from OC instead of Search.  TODO look into doing this more generally for all data.
  const ocProduct = product.variants?.find((x) => x.ID === product.id);
  //Getting an image from the product xp values and sorting the images
  const [isPickUpSelected, setIsPickUpSelected] = useState<boolean>();
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const { push } = useRouter();
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { addToCart } = useOcCart();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useOcDispatch();
  const image = getFirstProductImage(ocProduct?.xp?.Images || product?.xp?.Images);
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const wishlistData = useOcSelector((s) => s?.wishlist?.wishlist);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );

  //Getting the product price
  const productPriceForPLP = getProductPriceFromSearch(product);
  const inventoryStatus = useProductInventory(product);

  //Adding the current product into user wishlist
  const addToFavoriteClick = (
    product: BuyerProductWithXp | ProductSearchResultModelWithVariants,
    index: number
  ) => {
    const productId =
      (product as BuyerProductWithXp)?.ID || (product as ProductSearchResultModelWithVariants)?.id;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: GTMLabels?.recommendedProducts,
        });
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: index,
            clickFrom: GTMLabels?.recommendedProducts,
            storeId: myStoreData?.storeId,
            currentPage: GTMLabels?.recommendedProducts,
            pageItem: GTMLabels?.recommendedProducts,
          })
        );
      }
    }
  };

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
    setIsPickUpSelected(newMethod === FulfillmentType.DFS);
  }, [fulfillmentMethod, selectedStore?.storeId, componentContextData?.isDFSMethodChanged]);

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  const addToCartDisabled = ecommerceDisabled === 1 || inventoryStatus === 'OutOfStock';

  //pushing GTM data
  const addGTMData = (event?: React.MouseEvent<HTMLHeadingElement>) => {
    event && event?.stopPropagation();
    sendProductsPromotion({
      eventName: GTM_EVENT?.selectItem,
      data: product as ProductSearchResultModelWithVariants,
      currentPage: GTMLabels?.recommendedProducts,
      pageItem: GTMLabels?.recommendedProducts,
      position: index,
    });
    window && window?.sessionStorage?.setItem(GTMLabels?.position, index?.toString());
  };
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  const promoTagData = ocProduct?.xp?.PromoTag ?? product?.xp?.PromoTag;
  //   const onSale = ocProduct?.xp?.OnSale ?? product?.xp?.OnSale;

  const promoTag =
    promoTagData &&
    getProductPromoTag(promoTagData, myStoreData?.dataareaid, myStoreData.priceGroupArray);

  //   const onSaleImage = useOcSelector((state) => state?.otherSettingSlice?.data?.onSaleImage);

  return (
    <div key={product.id} className={clsx(sitecoreProductRoot({ className: 'h-full' }), '!h-full')}>
      {showOfferTooltip == (ocProduct?.ID || product.id) && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[85%] !visible !opacity-100 !left-1/2 -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={clsx(productImageAndTitleWrapper({ className: 'w-full px-0' }))}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation(), addGTMData();
          push(
            getProductDetailPath({
              ...(ocProduct ?? product),
              ParentID: ocProduct?.ParentID ?? product?.parentid,
            })
          );
        }}
      >
        <div className={clsx(productTopWrapper({ className: 'relative' }))}>
          <button
            aria-label="add to favorites"
            className={addToFavoritesWrapper()}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event?.stopPropagation();
              addToFavoriteClick(ocProduct || product, index);
            }}
          >
            <IconHelper
              className={addToFavoriteIcon()}
              icon={
                !isAnonymous && wishlistData && isProductInWishlist(wishlistData, product.id || '')
                  ? 'heart'
                  : 'heart-stroke'
              }
            />
          </button>
        </div>
        <ProductLink
          {...{ actions, index, product: ocProduct || product }}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            event.stopPropagation(), addGTMData();
          }}
        >
          <div className={sitecoreImage()}>
            <div className={sitecoreImageWrapper()}>
              <ImageHelper
                className={productImage()}
                field={{
                  value: {
                    src: image?.Url || dummyProductImage?.jsonValue?.value?.src,
                    alt: product?.name || dummyProductImage?.jsonValue?.value?.alt,
                    width: 140,
                    height: 156,
                  },
                }}
              />
            </div>
            {promoTag && (
              <div
                className={offerLabel()}
                onMouseEnter={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(ocProduct?.ID || product.id);
                  }
                }}
                onMouseLeave={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(false);
                  }
                }}
              >
                {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
              </div>
            )}
          </div>
        </ProductLink>
        {/* {(ocProduct?.xp?.RetailUnit || product?.xp?.RetailUnit) && (
          <div className="product-weight-container relative">
            <p className="absolute pr-3 pl-1 bottom-[100px] right-[0px] h-auto text-left bg-color-brand-primary-1-base text-color-text-white product-weight-clip">
              {ocProduct?.xp?.RetailUnit ?? product?.xp?.RetailUnit}{' '}
              {ocProduct?.xp?.RetailMeasure ?? product?.xp?.RetailMeasure}
            </p>
          </div>
        )} */}
        <ProductMoreOptions
          {...{ actions, product, index }}
          linkClassName={moreOptionLink()}
          className="h-auto !min-h-[24px]"
        />
        {/* Product Tile Info */}
        <ProductLink
          {...{ actions, index, product: ocProduct || product }}
          styles={sitecoreProductName()}
        >
          <h2 onClick={addGTMData}>{ocProduct?.Name ?? product.name}</h2>
        </ProductLink>
      </div>
      <div className={productInfoWrapper()}>
        {/* Product Tile Middle  */}
        <div className={productStockPriceInfo()}>
          <ProductInventoryMessage product={product} className="!justify-normal" />
          <ProductRecommendationPrice productPrice={productPriceForPLP} />
        </div>
        {/* Product Tile Bottom */}
        <div className={productBottomWrapper()}>
          {/* <ProductAutoship product={ocProduct ?? product} /> */}

          <button
            aria-label="add to cart"
            disabled={(addToCartDisabled as boolean) || isAddingToCart}
            onClick={async () => {
              setIsAddingToCart(true);
              const response = await addToCart({
                productId: ocProduct?.ID ?? (product.id as string),
                quantity: 1,
                ID: `${ocProduct?.ID ?? product.id}`,
              });
              if (
                (response && typeof response === 'object' && 'error' in response) ||
                (response &&
                  response?.payload?.errorCode &&
                  response?.payload?.errorCode == ErrorCodes.InventoryInsufficient)
              ) {
                setcomponentContextData({
                  ...componentContextData,
                  openMiniCart: true,
                  showMiniCartLoader: false,
                  outOfStockProductIds: [product.id as string],
                });
              } else {
                setHeaderContextData({
                  ...headerContextData,
                  openMiniCart: true,
                  showMiniCartLoader: false,
                  outOfStockProductIds: [''],
                });
              }
              setIsAddingToCart(false);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              sendProductsPromotion({
                eventName: GTM_EVENT?.addToCart,
                data: product as ProductSearchResultModelWithVariants,
                currentPage: GTMLabels?.recommendedProducts,
                pageItem: GTMLabels?.recommendedProducts,
                position: index,
                click_from: GTMLabels?.recommendedProducts,
                storeId: selectedStore?.storeId,
                fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
              });
              //Sending Add Cart event to Search
              trackAddEvent('product', 'cart', {
                items: [{ entityType: 'product', id: ocProduct?.ID ?? (product.id as string) }],
                actionSubtype: 'conversion',
              });
            }}
            className={clsx(submitBtn({ outlineCta: false }))}
          >
            {!isAddingToCart
              ? productPriceForPLP?.hasImapPrice || productPriceForPLP?.showViewPriceInCart
                ? getDictionaryValue('ViewPriceInCartPDP')
                : getDictionaryValue('AddToCartCTAPDP')
              : getDictionaryValue('AddingItemToCart')}
          </button>

          {/* Ratings to be placed here */}
          <BazarVoiceReviews
            bvProductId={(ocProduct?.xp?.UPC as string) || (product?.xp?.UPC as string)}
            bvRedirectUrl={getProductDetailPath({ ...product, ParentID: product?.parentid })}
          />
        </div>
      </div>
    </div>
  );
};
